import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import Head from '../components/head'

import imprintStyle from './imprint.module.scss'

const ImprintPage = () => {
  const {t} = useTranslation()
  return (
    <Layout>
      <Head title={t("Imprint")} />
      <h1 className="section-title"><Trans>Imprint</Trans></h1>
      <p className={imprintStyle.imprintText}>
      <Trans>Text One</Trans><br />
      <Trans>Text Two</Trans><br />
      Eichtalstraße 20<br />
      38114 Braunschweig<br />
      <Trans>Text Three</Trans><br />
      <a rel="noopener noreferrer" aria-label="Email" target="_blank" href="mailto:emre@ekener.io">
        emre@ekener.io
      </a><br />
      <a rel="noopener noreferrer" aria-label="Tel" target="_blank" href="tel:+4017682730479">
        +4917682730479
      </a><br />
      </p>
      <h1 className="section-title"><Trans>Header Two</Trans></h1>
      <p className={imprintStyle.imprintText}>
        <Trans>Text Four</Trans><br />
      </p>
    </Layout>
  )
}

export default ImprintPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;